import gql from "graphql-tag";

export default {
  methods: {
    getContentTemplates: async function() {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllTemplates {
            template {
              id
              name
              template
              updated_at
              created_at
            }
          }
        `,
      });
      return data.template;
    },
    getGeoJson: async function(lat, long, distance) {
      const { data } = await this.$apollo.query({
        query: gql`
          query GeoJson($distance: Int!, $lat: Float!, $long: Float!) {
            action_geojson(distance: $distance, lat: $lat, long: $long) {
              ok
              result
            }
          }
        `,
        variables: {
          lat,
          long,
          distance,
        },
      });
      if (data.action_geojson.ok) {
        return { ok: true, result: JSON.parse(data.action_geojson.result) };
      }
      return data.action_geojson;
    },
    createSeminar: async function(seminar) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateSeminar($seminar: campaigns_insert_input!) {
            insert_campaigns_one(object: $seminar) {
              id
            }
          }
        `,
        variables: {
          seminar,
        },
      });
      return data.insert_campaigns_one.id;
    },
    insertTerritories: async function(territories) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation InsertTerritories($objects: [territories_insert_input!]!) {
            insert_territories(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects: territories,
        },
      });
      return data.insert_territories.affected_rows;
    },
    processPayment: async function(
      seminar_id,
      token,
      description,
      amount,
      requested_count
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ProcessPayment(
            $seminar_id: Int!
            $token: String!
            $description: String!
            $amount: Int!
            $requested_count: Int!
          ) {
            action_process_payment(
              seminar_id: $seminar_id
              token: $token
              description: $description
              amount: $amount
              requested_count: $requested_count
            ) {
              ok
              reason
              receipt
              charge_id
            }
          }
        `,
        variables: {
          seminar_id,
          token,
          description,
          amount,
          requested_count,
        },
      });
      return data.action_process_payment;
    },
    updateSeminar: async function(seminar_id, seminar) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateSeminar(
            $seminar_id: Int!
            $seminar: campaigns_set_input!
          ) {
            update_campaigns_by_pk(
              pk_columns: { id: $seminar_id }
              _set: $seminar
            ) {
              id
            }
          }
        `,
        variables: {
          seminar_id,
          seminar,
        },
      });
      return data.update_campaigns_by_pk.id;
    },
    getEventsbyUser: async function(user_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllEvents($user_id: Int!) {
            campaigns(
              where: { user_id: { _eq: $user_id }, status: { _neq: "created" } }
            ) {
              id
              venue_name
              events {
                id
                start_date
                start_time
              }
            }
          }
        `,
        variables: {
          user_id,
        },
      });

      let events = [];
      for (const campaign of data.campaigns) {
        for (const event of campaign.events) {
          events.push({
            event_id: event.id,
            campaign_id: campaign.id,
            venue_name: campaign.venue_name,
            start_date: event.start_date,
            start_time: event.start_time,
          });
        }
      }
      return events;
    },
    createEvent: async function(event) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateEvent($event: events_insert_input!) {
            insert_events_one(object: $event) {
              id
            }
          }
        `,
        variables: {
          event,
        },
      });
      return data.insert_events_one.id;
    },
    clearEvents: async function(campaign_id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteEvents($campaign_id: Int!) {
            delete_events(where: { campaign_id: { _eq: $campaign_id } }) {
              affected_rows
            }
          }
        `,
        variables: {
          campaign_id,
        },
      });
      return data.delete_events.affected_rows;
    },
    campaignList: async function(user_id) {
      let result = await this.$apollo.query({
        query: gql`
          query CampaignList($user_id: Int!) {
            campaigns(
              where: { user_id: { _eq: $user_id }, status: { _neq: "created" } }
            ) {
              id
              city
              state
              requested_count
              venue_name
              events {
                id
                start_date
                start_time
                capacity
                households {
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          user_id: user_id,
        },
      });
      return result.data.campaigns;
    },

    campaignListAdmin: async function() {
      const { data } = await this.$apollo.query({
        query: gql`
          query campaignListAdmin {
            campaigns(where: { status: { _neq: "created" } }) {
              id
              city
              state
              requested_count
              status
              venue_name
              user {
                id
                name
              }
              events {
                start_date
                start_time
                capacity
                households {
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `,
      });
      return data.campaigns;
    },
    campaignById: async function(id) {
      let result = await this.$apollo.query({
        query: gql`
          query CampaignById($id: Int!) {
            campaigns_by_pk(id: $id) {
              id
              requested_count
              city
              mail_date
              state
              status
              venue_name
              street
              created_at
              zip
              user {
                id
                name
                phone
              }
              events {
                capacity
                start_date
                start_time
                households {
                  id
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
                id
              }
              households {
                email
                status
                user_id
                people {
                  name
                  primary
                  id
                }
                event_id
                campaign_id
                phone
                street
                city
                state
                zip
                sticky_notes
                lead_type
                created_at
                id
              }
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return result.data.campaigns_by_pk;
    },
    updateEventCapacity: async function(id, capacity) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation updateEventCapacity($id: Int!, $capacity: Int!) {
            update_events(
              where: { id: { _eq: $id } }
              _set: { capacity: $capacity }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: id,
          capacity: capacity,
        },
      });
      return result.data.update_events;
    },
    insert_person: async function(household_id, person) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation InsertPerson(
            $household_id: Int!
            $name: String!
            $primary: Boolean!
          ) {
            insert_people_one(
              object: {
                household_id: $household_id
                name: $name
                primary: $primary
              }
            ) {
              id
              name
              primary
            }
          }
        `,
        variables: {
          household_id,
          name: person.name,
          primary: person.primary || false,
        },
      });
      return data.insert_people_one;
    },
    delete_person_by_pk: async function(person_id) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation DeletePerson($person_id: Int!) {
            delete_people_by_pk(id: $person_id) {
              id
            }
          }
        `,
        variables: {
          person_id: person_id,
        },
      });
      return result.data.delete_people_by_pk.id;
    },
    update_person_by_pk: async function(person_id, person) {
      const _person = { ...person };
      delete _person.id;
      delete _person.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdatePerson($id: Int!, $_set: people_set_input) {
            update_people_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: person_id,
          _set: _person,
        },
      });
      return data.update_people_by_pk;
    },
    insert_client: async function(client) {
      const _client = { ...client };
      delete _client.id;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_clients_one($client: clients_insert_input!) {
            insert_clients_one(object: $client) {
              id
            }
          }
        `,
        variables: {
          client: _client,
        },
      });
      return data.insert_clients_one.id;
    },

    aws_presigned_get: async function(current_annuity_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query action_aws_presigned_get($current_annuity_id: Int!) {
            action_aws_presigned_get(current_annuity_id: $current_annuity_id) {
              ok
              result
            }
          }
        `,
        variables: {
          current_annuity_id,
        },
      });
      return data.action_aws_presigned_get;
    },

    current_annuity_report: async function(current_annuity_id) {
      console.log("current_annuity_id", current_annuity_id);
      const { data } = await this.$apollo.query({
        query: gql`
          query current_annuity_by_pk($id: Int!) {
            current_annuity_by_pk(id: $id) {
              id
              result
              has_pdf
              current_value
              original_value
              carrier_name
              product_name
              description
              asset_type
              mne
              admin
              expense_ratio
              additional_fees
              total_wds
              issue_date
              statement_date
              is_income_rider
              free_wd_percent
              first_year_free_wd_percent
              free_wd_type
              qualified
              total_surrender_dollar
              total_withdrawals_since_statement
              additional_wds_prior_transfer
              income_rider_amount
              total_wds
              surrender_years
              surrender_values
              income_start_age
              valid
            }
          }
        `,
        variables: {
          id: current_annuity_id,
        },
      });
      console.log("returning report data", data.current_annuity_by_pk);
      return data.current_annuity_by_pk;
    },

    insert_current_annuity: async function(current_annuity) {
      const _current_annuity = { ...current_annuity };
      delete _current_annuity.id;
      delete _current_annuity.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_clients_one(
            $current_annuity: current_annuity_insert_input!
          ) {
            insert_current_annuity_one(object: $current_annuity) {
              id
            }
          }
        `,
        variables: {
          current_annuity: _current_annuity,
        },
      });
      return data.insert_current_annuity_one.id;
    },
    update_current_annuity: async function(id, current_annuity) {
      const _current_annuity = { ...current_annuity };
      delete _current_annuity.id;
      delete _current_annuity.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_current_annuity_by_pk(
            $id: Int!
            $_set: current_annuity_set_input!
          ) {
            update_current_annuity_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: id,
          _set: _current_annuity,
        },
      });
      return data.update_current_annuity_by_pk.id;
    },
    client_by_user_id: async function(user_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query UserClients($user_id: Int!) {
            clients(where: { user_id: { _eq: $user_id } }) {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              user {
                id
                name
              }
              client_current_annuities {
                id
                qualified
                valid
                result
                created_at
                carrier_name
                product_name
                issue_date
                statement_date
                asset_type
                current_value
                has_pdf
              }
            }
          }
        `,
        variables: {
          user_id,
        },
      });
      return data.clients;
    },
    client_by_pk: async function(client_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ReadClient($client_id: Int!) {
            clients_by_pk(id: $client_id) {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              client_current_annuities {
                id
                valid
                result
                created_at
                carrier_name
                product_name
                issue_date
                statement_date
                asset_type
                current_value
                qualified
                has_pdf
              }
            }
          }
        `,
        variables: {
          client_id,
        },
      });
      return data.clients_by_pk;
    },
    update_clients_by_pk: async function(client_id, client) {
      const _client = { ...client };
      delete _client.id;
      delete _client.__typename;
      delete _client.sticky_notes;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateClient($id: Int!, $_set: clients_set_input) {
            update_clients_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: client_id,
          _set: _client,
        },
      });
      return data.update_clients_by_pk;
    },
    delete_client_by_pk: async function(id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteClient($id: Int!) {
            delete_clients_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return data.delete_clients_by_pk;
    },
    delete_household_by_pk: async function(id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteUser($id: Int!) {
            delete_households_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return data.delete_households_by_pk;
    },
    insert_household: async function(household) {
      const _household = { ...household };
      delete _household.id;
      delete _household.people;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateHousehold($household: households_insert_input!) {
            insert_households_one(object: $household) {
              id
            }
          }
        `,
        variables: {
          household: _household,
        },
      });
      return data.insert_households_one.id;
    },
    update_household_by_pk: async function(household_id, household) {
      const _household = { ...household };
      delete _household.id;
      delete _household.__typename;
      delete _household.people;
      delete _household.created_at;
      delete _household.updated_at;
      delete _household.sticky_notes;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUser($id: Int!, $_set: households_set_input) {
            update_households_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: household_id,
          _set: _household,
        },
      });
      return data.update_households_by_pk;
    },
    updateHouseholdNotes: async function(id, notes) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation updateHouseholdNotes($id: Int!, $notes: _text!) {
            update_households(
              where: { id: { _eq: $id } }
              _set: { sticky_notes: $notes }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: id,
          notes: `{${notes.join(",")}}`,
        },
      });
      return result.data.update_events;
    },
    sendTemplateEmail: async function(
      recipient_email,
      recipient_name,
      template_id,
      variables
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation SendTemplateEmail(
            $data: String!
            $recipient_email: String!
            $recipient_name: String!
            $template_id: String!
          ) {
            action_send_template_email(
              data: $data
              recipient_email: $recipient_email
              subject: ""
              template_id: $template_id
              recipient_name: $recipient_name
            ) {
              ok
            }
          }
        `,
        variables: {
          recipient_email,
          recipient_name,
          template_id,
          data: JSON.stringify(variables),
        },
      });
      return data.action_send_template_email.ok;
    },
    sendEmail: async function(recipient_email, recipient_name, subject, html) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation SendEmail(
            $html: String!
            $recipient_email: String!
            $recipient_name: String!
            $subject: String!
          ) {
            action_send_email(
              html: $html
              recipient_email: $recipient_email
              recipient_name: $recipient_name
              subject: $subject
            ) {
              ok
            }
          }
        `,
        variables: {
          html,
          recipient_email,
          recipient_name,
          subject,
        },
      });
      return data.action_send_email.ok;
    },
    registerUser: async function(email, name, phone, street, city, state, zip) {
      const mutation = gql`
        mutation VueRegistration(
          $email: String!
          $name: String!
          $phone: String!
          $street: String!
          $city: String!
          $state: String!
          $zip: String!
        ) {
          action_registration(
            city: $city
            email: $email
            name: $name
            phone: $phone
            state: $state
            street: $street
            zip: $zip
            password: $password
          ) {
            ok
            id
            reason
          }
        }
      `;
      const { data } = await this.$apollo.mutate({
        mutation,
        variables: {
          email,
          name,
          phone,
          street,
          city,
          state,
          zip,
        },
      });
      return data.action_registration;
    },
    login: async function(fbid) {
      // NOT USED??
      const { data } = await this.$apollo.query({
        query: gql`
          query Login($fbid: String!) {
            users(where: { fbid: { _eq: $fbid } }) {
              id
              acid
              admin
              book_disclaimer
              business_name
              category
              city
              created_at
              email
              email_disclaimer
              fbid
              hidden
              name
              phone
              state
              status
              sticky_notes
              street
              updated_at
              zip
            }
          }
        `,
        variables: {
          fbid,
        },
      });
      return data.users.length ? data.users[0] : null;
    },
    getLE: async function(gender, age) {
      let result = await this.$apollo.query({
        query: gql`
          query action_get_life_exp($gender: String!, $age: Int!) {
            action_get_life_exp(age: $age, gender: $gender) {
              ok
              life_exp
            }
          }
        `,
        variables: {
          gender: gender,
          age: age,
        },
      });
      return result.data.action_get_life_exp;
    },
    get_marketers: async function() {
      const { data } = await this.$apollo.query({
        query: gql`
          query GetMarketers {
            users(where: { admin: { _eq: true }, id: { _neq: 5 } }) {
              id
              name
            }
          }
        `,
      });

      return data.users.map((user) => {
        return {
          id: user.id,
          text: `${user.name}`,
          name: user.name,
        };
      });
    },
    delete_user_by_pk: async function(id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteUser($id: Int!) {
            delete_users_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });

      return data.action_delete_user;
    },
    update_user_sticky_by_pk: async function(user_id, sticky) {
      let _user = {
        sticky_notes: `{${sticky.join(",")}}`,
      };
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUser($id: Int!, $_set: users_set_input) {
            update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: user_id,
          _set: _user,
        },
      });
      return data.update_users_by_pk;
    },
    update_user_by_pk: async function(user_id, user) {
      const _user = { ...user };
      delete _user.id;
      delete _user.__typename;
      delete _user.allowed_carriers;
      delete _user.admin;
      delete _user.created_at;
      delete _user.updated_at;
      delete _user.sticky_notes;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUser($id: Int!, $_set: users_set_input) {
            update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: user_id,
          _set: _user,
        },
      });
      return data.update_users_by_pk;
    },
    get_user_by_pk: async function(uid) {
      const { data } = await this.$apollo.query({
        query: gql`
          query AdminUser($user_id: Int!) {
            users_by_pk(id: $user_id) {
              id
              admin
              business_name
              city
              email
              name
              hidden
              phone
              state
              status
              sticky_notes
              street
              zip
            }
          }
        `,
        variables: {
          user_id: uid,
        },
      });

      return data.users_by_pk;
    },
    get_bonus_fee: async function(product_id, state, age) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ZLTBonus($product_id: Int!, $state: jsonb, $age: Int) {
            products_by_pk(id: $product_id) {
              state_availabilities(where: { states: { _contains: $state } }) {
                fee
                bonuses(
                  where: { max_age: { _gte: $age }, min_age: { _lte: $age } }
                ) {
                  bonus_amount
                }
              }
            }
          }
        `,
        variables: {
          product_id,
          state,
          age,
        },
      });
      let bonus = data.products_by_pk?.state_availabilities[0]?.bonuses[0] || 0;
      return {
        bonus: bonus ? bonus.bonus_amount : 0,
        fee: data.products_by_pk?.state_availabilities[0]?.fee || 0,
      };
    },
    get_products_by_cid: async function(cid, state, age) {
      let response = await this.$apollo.query({
        query: gql`
          query AllProducts($carrier_id: Int!, $state: jsonb = "", $age: Int) {
            products(where: { carrier_id: { _eq: $carrier_id } }) {
              id
              name
              state_availabilities(
                where: {
                  states: { _contains: $state }
                  min_age: { _lte: $age }
                  max_age: { _gte: $age }
                }
              ) {
                fee
                bonuses(
                  where: { min_age: { _lte: $age }, max_age: { _gte: $age } }
                ) {
                  bonus_amount
                }
              }
            }
          }
        `,
        variables: {
          carrier_id: cid,
          state,
          age,
        },
      });

      let products = [];
      response.data.products.forEach((product) => {
        if (product.state_availabilities.length > 0) {
          products.push({
            id: product.id,
            name: product.name,
            bonus:
              product.state_availabilities[0].bonuses[0]?.bonus_amount || 0,
            fee: product.state_availabilities[0].fee,
          });
        }
      });

      products.sort(function(a, b) {
        return a.name < b.name ? -1 : 1;
      });

      return products;
    },
    get_by_pk: async function(table_name, pk, columns) {
      const response = await this.$apollo.query({
        query: gql`query get_by_pk($id: Int!) {
          ${table_name}_by_pk(id: $id) {
            ${columns}
          }
        }`,
        variables: {
          id: pk,
        },
      });
      return response.data[table_name + "_by_pk"];
    },
    get_by_all: async function(table_name, columns) {
      const response = await this.$apollo.query({
        query: gql`query get_by_all_${table_name} {
          ${table_name} {
            ${columns}
          }
        }`,
      });
      return response.data[table_name];
    },
  },
};
